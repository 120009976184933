<template>
  <div>
    <v-row
      ><div class="display-1 pa-2 font-weight-light">
        Placement Dashboard
      </div></v-row
    >
    <v-card color="grey lighten-4 ">
      <v-row>
        <v-col cols="12" sm="3">
          <v-select
            v-model="selay"
            multiple
            chips
            label="Academic Year"
            class="ma-5"
            item-text="name"
            item-value="id"
            :items="aylist"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            v-model="selorg"
            multiple
            chips
            label="Ogranization"
            class="ma-5"
            item-text="name"
            item-value="id"
            :items="orglist"
            deletable-chips
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn class="primary ma-5 mt-10" @click="getplacementdashboardreport"
            >Get Details</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>
    <v-card v-else-if="Array.isArray(tabledata) && tabledata.length" class="ma-3 pa-2">
     <v-row justify="end">
     <v-col cols="12" sm=3>
     <v-text-field
     style="padding:0px;margin:0px;"
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
          ></v-text-field>
     </v-col>
    <!--- <download-excel
              class="mx-5"
              :data="headers"
              :fields="tabledata"
              worksheet="Company_List"
              name="Company_List.xls"
            >
              <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>-->
            <v-btn  @click="exportexcel()" color="success" dark class="mx-5">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
     </v-row>
      <div style="width: 100%; overflow: auto">
     <v-data-table  id="exceltable"
      :headers="headers"
      :items="tabledata"
      class="elevation-1"
      :search="search"
    >

     <template v-slot:item.total_student="{ item }">
      <v-chip v-if="item.total_student==0"
        color="red"
        dark
      >
        {{ item.total_student }}
      </v-chip>
      <v-chip v-else
        color="green"
        dark
      >
        {{ item.total_student }}
      </v-chip>
    </template>
    </v-data-table>
      </div>
    </v-card>
  
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Language from "../AdminMaster/Language.vue";
import {table2excel} from "../jquery.table2excel";
export default {
  components: { Language },
  data() {
    return {
      selorg: [],
      selay: [],
      orglist: [],
      aylist: [],
      snackbar_msg: "",
      snackbar: false,
      color: "",
      data: [],
      tabledata: [],
      dataarray: [
       
      ],
      loading: false,
      headers: [],
      search: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
      exportexcel() {
     
        $("#exceltable").table2excel({
    
    name: "Worksheet Name",
    filename: "PLACEMENT_DASHBOARD_REPORT", //do not include extension
    fileext: ".xls" // file extension
  }); 
   
    },
    init() {
      axios.post("/PlacementReport/getorganizationlist").then((res) => {
        if (res.data.msg == "200") {
          this.orglist = res.data.orglist;
          this.aylist = res.data.aylist;
        }
      });
    },
    getplacementdashboardreport() {
      if (this.selorg.length == 0) {
        this.showSnackbar("red", "Please Select Organization");
      }
      if (this.selay.length == 0) {
        this.showSnackbar("red", "Please Select Academic Year");
      } else {
        this.data = [];
        this.loading = true;
        var params = { orglist: this.selorg, aylist: this.selay };
        axios
          .post("/PlacementReport/getplacementdashboardreport", params)
          .then((res) => {
            this.loading = false;
            if (res.data.msg == "200")
             {
                  this.data = res.data.data;
                  this.tabledata = res.data.tabledata;
                //  console.log(this.tabledata)
                  var id = 0;
                    var temp = { text: "Sr.No", value: "Sr_No" };  //kedar
                    this.headers.push(temp);                       //kedar
                    var temp = { text: "Cname", value: "company_name" };
                    this.headers.push(temp);
                    var temp = { text: "TOTAL", value: "total_student" };
                    this.headers.push(temp);
                    for (var i in this.data[0].deptdetails) {
                      // for (var i =0;i<=5;i++) {
                      var temp = {
                        text: this.data[0].deptdetails[i].progname,
                        value: this.data[0].deptdetails[i].id.toString()
                      };
                      this.headers.push(temp);
                    }
                    //console.log(this.data[0].deptdetails)
                    
                  
                    //  console.log(this.dataarray);
            }else if(res.data.msg == "402"){
                   this.showSnackbar("red", res.data.error);
            }
          });
      }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>